<template>
  <v-menu
    v-model="show"
    :close-on-content-click="closeOnContentClick"
    :min-width="minWidth"
    :offset-y="offsetY"
    :ref="refName"
    :transition="transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        :background-color="'#fff'"
        :label="label"
        :outlined="outlined"
        :prepend-icon="prependIcon"
        :readonly="readonly"
        :rules="rules"
        :value="formattedDate"
      />
    </template>
    <v-date-picker
      :allowed-dates="allowedDates"
      :color="color"
      :day-format="date => new Date(date).getDate()"
      :header-color="headerColor"
      :locale="locale"
      :no-title="noTitle"
      :scrollable="scrollable"
      :value="value"
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'BaseDatePicker',
  props: {
    allowedDates: {
      type: Function,
      default: null
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    dateFormat: {
      type: String,
      default: 'YYYY年MM月DD日'
    },
    headerColor: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'ja-JP'
    },
    minWidth: {
      type: String,
      default: ''
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prependIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    readonly: {
      type: Boolean,
      default: true
    },
    refName: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    formattedDate: {
      get() {
        return this.value ? dayjs(this.value).format(this.dateFormat) : this.value;
      },
      set() {
        // setterは何もしない
      }
    }
  },
  methods: {
    onInput(e) {
      this.show = false;
      this.$emit('input', e);
    }
  }
};
</script>
